<template>
  <div>
    <WidgetsStat v-on:selectSubscription="switchSubscription" />
    <CRow>
      <CCol sm="12">
        <CCard>
          <CCardHeader>
            <slot name="header">
              <CRow>
                <CCol sm="2">
                  <CIcon name="cil-grid" />
                  {{ $t(caption) }}
                </CCol>

                <CCol sm="3" md="4">
                  <CInput
                    type="text"
                    :placeholder="$t('Search')"
                    v-model="search"
                    id="domain_search_input"
                    size="sm"
                  >
                    <template #append>
                      <CButton type="submit" id="domain_search_btn" color="primary" @click="filterBySearch">{{ $t('Search') }}</CButton>
                      <CButton type="button" id="domain_filter_btn" color="primary" @click="$refs.filterBox.classList.toggle('Show')">{{ $t('Filter') }}</CButton> 
                      <CButton type="button" id="domain_find_btn" color="primary" @click="findDomainModal = !findDomainModal">{{ $t('FindDomain') }}</CButton>

                      <CModal :title="$t('FindDomain')" key="findDomainModal" color="primary" :show.sync="findDomainModal" id="findDomainModal">
                        <CTextarea rows="15" :placeholder="$t('PlaceholderFindDomain')" v-model="findMultiDomain"/> 
                        <template v-slot:footer>
                          <button
                              type="button"
                              class="btn btn-secondary"
                              @click="closeFindMultiDomain();">
                            {{ $t('Close') }}
                          </button>
                          <button type="button"
                                  @click="findMultiDomain = ''"
                                  class="btn">
                            {{ $t('Clear') }}
                          </button>
                          <button
                            type="button"
                            class="btn btn-primary"
                            @click="findBtnClicked = true; fetchDomain()">
                            {{ $t('Find') }}
                          </button>
                        </template>
                      </CModal>

                    </template>
                  </CInput>
                  <div id="filterBox" class="row"
                       ref="filterBox">
                    <CCol>
                      <CRow>
                        <CCol>
                          <label size="sm" for="domain_cert_status">{{ $t('CertStatus') }}</label>
                          <CSelect :options="[{'value': '', 'label': $t('all')}, {'value': 'https', 'label': $t('SSLActive')},{'value': 'http', 'label': $t('SSLInactive')}, {'value': 'force', 'label': $t('ForceSSL')},]"
                                   size="sm"
                                   id="domain_cert_status"
                                   :value.sync="certStatus">
                          </CSelect>
                        </CCol>
                 
                        <CCol>
                          <label size="sm" for="domain_status">{{ $t('DomainStatus') }}</label>
                          <CSelect :options="[{'value': '', 'label': $t('all')}, {'value': 'disabled', 'label': $t('disabled')}, {'value': 'running', 'label': $t('running')}]"
                                   size="sm"
                                   id="domain_status"
                                   :value.sync="domainStatus">
                          </CSelect>
                        </CCol>

                        <CCol>
                          <label size="sm" for="sort_by">{{ $t('sort_by') }}</label>
                          <CSelect :options="[{'value': 'newest', 'label': $t('newest_to_oldest')}, {'value': 'oldest', 'label': $t('oldest_to_newest')}, {'value': 'domain_ascending', 'label': $t('domain_ascending')}, {'value': 'domain_descending', 'label': $t('domain_descending')}]"
                                   size="sm"
                                   id="sort_by"
                                   :value.sync="sortByFilter">
                          </CSelect>
                        </CCol>
                      </CRow>

                      <CButton type="button"
                               color="primary"
                               size="sm"
                               class="pull-right"
                               id="filter_apply"
                               @click="applyFilter">
                        {{ $t('Apply') }}
                      </CButton>
                    </CCol>
                  </div>
                </CCol>
                <CCol auto class="mr-auto">
                </CCol>
                
                <CCol sm="12" :md="enableSelect ? '12' : '4'" class="text-right">
                  <BatchOperationGroup>
                    <CButton color="success" @click="startSelect"  v-if="!enableSelect">
                      <CIcon name="cil-playlist-add" />{{ $t('BatchOperation') }}
                    </CButton>

                    <CButton color="success" @click="endSelect" v-if="enableSelect">{{ $t('Cancel') }}</CButton>
                    <CButton variant='outline' color="primary" @click="selectAll" v-if="enableSelect">{{ $t('SelectAll') }}</CButton>
                    <CButton variant='outline' color="primary" @click="patchDeploy" :disabled="selectedDomains.length === 0" v-if="enableSelect">{{ $t('DeployAll') }}</CButton>
                    <CButton variant='outline' color="primary" @click="patchSSL" :disabled="selectedDomains.length === 0" v-if="enableSelect">{{ $t('EnableHTTPS') }}</CButton>
                    <CButton variant='outline' color="primary" @click="enableForceSSL" :disabled="selectedDomains.length === 0" v-if="enableSelect">{{ $t('EnableForceHTTPS') }}</CButton>
                    <CButton variant='outline' color="primary" @click="disableForceSSL" v-if="enableSelect">{{ $t('DisableForceHTTPS') }}</CButton>
                    <CButton variant='outline' color="primary" @click="patchCleancache" v-if="enableSelect">{{ $t('ClearCache') }}</CButton>
                    <CButton variant='outline' color="primary" @click="doDiagnoseCheck" v-if="enableSelect">{{ $t('domain.Diagnose') }}</CButton>
                    <CButton variant='outline' color="primary" @click="patchDomain" v-if="enableSelect">{{ $t('BatchEdit') }}</CButton>
                    <CButton variant='outline' color="primary" :to="{name:'DomainImport'}"  v-if="enableSelect" class="d-flex align-items-center justify-content-center" role="button">{{ $t("domain.Import") }}</CButton>
                    <CButton variant='outline' color="primary" @click="exportAllDomain" v-if="enableSelect && total_rows > 0">{{ $t('Download') }}</CButton>

                      <a-popconfirm
                        v-if="items.length > 0"
                        :title="$t('message.ConfirmDomainAdd')"
                        :ok-text="$t('Yes')"
                        :cancel-text="$t('No')"
                        @confirm="cancelAddDomain"
                        @cancel="addDomain"
                      >
                        <a-button class="btn btn-success">
                          <CIcon name="cil-plus" />
                          {{ $t('domain.AddDomain') }}
                        </a-button>
                      </a-popconfirm>

                      <a-button class="btn btn-success" v-else @click="addDomain">
                        <CIcon name="cil-plus" />
                        {{ $t('domain.AddDomain') }}
                      </a-button>

                    <CButton color="success" @click="fetchDomain">
                      <CIcon name="cil-reload" />
                      {{ $t("Refresh")}}
                    </CButton>

                  </BatchOperationGroup>
                </CCol>
              </CRow>
              
              <!--
              <CRow>
                <CCol>
                  <CAlert color="warning">
                    <p v-html="$t('MaintenanceAnnouncement')"/>
                  </CAlert>
                </CCol>
              </CRow>
            -->
              
              <CRow class="mt-2">
                <CCol>
                  <CAlert color="success" id="display-cname">
                      <CLink
                        color="warning"
                        v-c-tooltip="{content: $t('message.add_following_cname_reminder'), placement: 'right' }"
                        variant="outline"
                        size="sm"
                      >&nbsp;&nbsp;&nbsp;&nbsp;
                        <CIcon name="cil-lightbulb" />
                      </CLink>
                      <span>{{ $t('ExclusiveCNAME') }}: </span><strong>{{ cname }}</strong>
                  </CAlert>
                  
                  <!--<div>
                    <CAlert color="info">
                      <p><strong>{{ $t('GenevaAnnouncement') }}</strong></p>
                      <span v-html="$t('GenevaAnnouncementDescription')"></span>
                    </CAlert>
                  </div>-->
                </CCol> 
              </CRow>
              <CRow v-if="is_cname_loaded && plan === null">
                <CCol>
                  <CAlert color="success">
                      <span>{{ $t('TryOurNewVersion') }}: </span><a :href="link_new_version" target="_blank"><strong>{{ link_new_version }}</strong></a>
                  </CAlert>
                </CCol>
              </CRow>
            </slot>
          </CCardHeader>
          <CCardBody>
            <CDataTable
              :hover="hover"
              :striped="striped"
              :bordered="bordered"
              :fixed="fixed"
              :items="items"
              :fields="domainListFields"
              :dark="dark"
              :loading="listLoading"
              :items-per-page="perpage"
              :items-per-page-select='{"label": $t("NumberOfItemsPerPage"), "values": [100,250,1000]}'
              @pagination-change="pageChanged"
              @row-clicked="selectRow"
              ref="domainTable"
            >
              <template #over-table>
                <div style="display: flex;justify-content: space-between;">
                  <CButton>
                    <span v-if="enableSelect">Selected Domains: {{ selectedDomainCount }}</span>
                  </CButton>
                  <CButton color="link" @click="showDomainNotes = !showDomainNotes">
                    <CIcon name="cil-notes"/>&nbsp;
                    <span v-if="showDomainNotes">{{ $t('domain.HideDomainNotes') }}</span>
                    <span v-else>{{ $t('domain.ShowDomainNotes') }}</span>
                  </CButton>
                </div>
              </template>

              <template #status="{item}">
                <td>
                  <CButtonGroup v-show="!enableSelect">
                    <CButton color="primary" variant="outline" square @click="handleDeploy(item)"
                             :disabled="item.deploy_status === 'running'">
                      <CIcon
                        name="cil-clock"
                        v-if="item.deploy_status==='ready'"
                        v-c-tooltip="$t('domain.WaitingForRedeployment')"
                      />
                      <CSpinner color="success" size="sm" v-else-if="item.deploy_status=='running'" />
                      <CIcon v-else-if="item.status == 'disabled'" name="cil-media-pause" />
                      <CIcon v-else :name="getStatusIcon(item)" />

                      {{ $t("domain.Deploy") }}
                    </CButton>
                  </CButtonGroup>
                </td>
              </template>

              <template #sourceConfig="{item, index}">
                <td v-if="item.sourceConfig && item.sourceConfig.full_url">
                  <div class="source-config-value">
                    <CIcon v-if="item.sourceConfig.redirect" 
                           name="cil-arrow-circle-right" 
                           v-c-tooltip="{content: $t('domain.RedirectToUrl'), delay: 500 }" />
                    <span>{{ item.sourceConfig.full_url }}</span>
                    <a v-if="item.sourceConfig.redirect" target="_blank" :href="item.sourceConfig.full_url" style="margin-left: 2px">
                      <CIcon name="cil-external-link" />
                    </a>
                  </div>
                </td>
                <td v-else>&nbsp;</td>
              </template>
              
              <template #notes="{item}">
                <td v-if="item.notes">
                  {{truncateLongNotes(item.notes)}}
                </td>
                <td v-else>&nbsp;</td>
              </template>

              <template #shadows="{item, index}">
                <td v-if="item.shadows.length">
                  <CBadge
                    class="success"
                    v-for="shadow in item.shadows.filter(shadow => shadow.status !== 'disabled')"
                    :key="shadow.name"
                  >
                    {{ shadow.name }}
                  </CBadge>
                </td>
                <td v-else>-</td>
              </template>

              <template #domain="{item, index}">
                <td class="caesar-tooltip">
                  <span class="caesar-tooltiptext"
                        v-if="item._message"
                        :class="[{show: item._message.show}, item._message.type]">
                    {{ item._message.content }}
                  </span>
                  {{ item.name }}
                  <a target="_blank" :href="getLink(item)">
                    <CIcon name="cil-external-link" />
                  </a>

                </td>
              </template>
              <template #lastDeployed="{ item, index }">
                <td>
                  <div v-c-tooltip="{ content: $moment(item.deployed_time).format('MMMM Do YYYY, h:mm:ss a'), placement: 'top' }">
                    {{ deploymentMessage(item) }}
                  </div>
                </td>
              </template>
              <template #actions="{item, index}">
                <td>
                  <CButtonGroup v-show="enableSelect===false">
                    <CButton
                      color="primary"
                      variant="outline"
                      square
                      @click="editDomain(item)"
                      class="float-right"
                    >{{ $t('domain.Edit') }}</CButton>

                    <CButton color="primary" variant="outline" square @click="showSslConfig(item)">
                      <CIcon name="cil-lock-locked" v-if="item.sslConfig.enabled"
                             :class="{GreenLock: item.sslConfig.forceSSL}"/>
                      {{ $t('domain.ConfigureSSL') }}
                    </CButton>

                    <!--
                    <CButton
                      color="primary"
                      variant="outline"
                      square
                      @click="showClusterUpgrade(item)"
                    >{{ $t('cluster.UpgradeServiceArea') }}</CButton>
                    -->

                    <CButton
                      color="primary"
                      variant="outline"
                      square
                      @click="cleancache(item)"
                    >
                      <CIcon name="cil-reload" />&nbsp;
                      <span>{{ $t('domain.ClearCache') }}</span>
                    </CButton>

                    <CButton color="primary" variant="outline" square @click="diagnose(item)">{{ $t('domain.Diagnose') }}</CButton>

                  </CButtonGroup>
                  <CButtonGroup v-show="enableSelect===true">
                    <CButton color="primary" variant="outline" square>
                      <CIcon name="cil-check-alt" v-show="item._selected" />
                      <CIcon v-show="!item._selected" />
                    </CButton>
                    <CSpinner grow color="primary" size="sm" v-if="item._patch_status=='processing'" />
                    <CIcon name="cil-check-alt" v-if="item._patch_status=='done'" />
                  </CButtonGroup>

                </td>
              </template>
              <template v-slot:no-items-view>
                <CJumbotron color="info" text-color="white" border-color="dark">
                  <h1 class="display-3">{{ $t('widget.AddDomainName') }}</h1>
                  <hr class="my-4" />
                  <CButton size="lg" color="success" @click="addDomain">{{ $t('widget.AddDomainNameNow') }}</CButton>
                </CJumbotron>
              </template>
            </CDataTable>

            <CPagination
              :activePage.sync="page"
              :pages="total_pages"
              align="center"
              @update:activePage="fetchDomain"
            />
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <EditDomain :source-domain="selectedDomain" @save="saveDomain" @refresh="refresh" @hide="editModal=false" :editShow.sync="editModal" id="editModal" @onStopConfirmModal="attachDomainWsHandler"/>
    <PreSetDomainConfiguration :source-domain="selectedDomain"
                @hide="presetModal=false"
                :presetShow.sync="presetModal" id="presetModal"
                />

    <PatchDomain ref="patchDomainModal"
                 :domains="selectedDomains"
                 @save="patchSave"
                 @refresh="refresh"
                 @hide="endSelect"
                 :patchShow.sync="patchModal"
                 id="patchModal"
                 :queueDomainDeactivate.sync="queueDomainDeactivate"
                 :onProgressDomainDeactivate.sync="onProgressDomainDeactivate"
                 :queueDomainResume.sync="queueDomainResume"
                 :onProgressDomainResume.sync="onProgressDomainResume"
                 :queueDomainDelete.sync="queueDomainDelete"
                 :onProgressDomainDelete.sync="onProgressDomainDelete"
                 @onResume="onPatchResume"
                 @onDelete="onPatchDelete"
                 @onDeactivate="onPatchDeactivate"/>

    <SslConfig v-if="selectedDomain" :domain.sync="selectedDomain" :isVisible.sync="sslModal" @hide="handleHideSslConfig" @auto-generate="redeploy" />

    <CModal :title="diagnoseTitle" :key="diagnoseKey" color="warning" :show.sync="diagnoseModal" id="diagnoseModal">
      <Diagnose :domain="selectedDomain" :isActive="diagnoseModal" />
    </CModal>

    <CModal :title="$t('Diagnose')"
            color="warning"
            :show.sync="showSummaryDiagnoseModal">
      <BatchDiagnose :domains="selectedDomains"
                     :diagnose="showSummaryDiagnoseModal"
                     v-if="showSummaryDiagnoseModal" />
    </CModal>

    <CModal
      :title="$t('cluster.UpgradeServiceArea')"
      color="warning"
      :show.sync="clusterUpgradeModal"
      v-if="selectedDomain && clusterUpgradeModal"
      id="clusterUpgradeModal"
    >
      <ClusterUpgrade :domain="selectedDomain" @hide="clusterUpgradeModal=false" />
      <template slot="footer-wrapper">
        <footer class="modal-footer" style="justify-content: space-between;">&nbsp;</footer>
      </template>
    </CModal>

    <CModal :title="$t('message.DomainActivationConfirmation')" color="warning" :show.sync="showConfirmActivateModal">
      <div>
        <CAlert color="warning">
          <p><strong>{{ $t("Domain") }}： {{ selectedDomain ? selectedDomain.name : '' }}</strong></p>
          <p>{{ $t('message.domain_for_activation_deploy') }}</p>
        </CAlert>
      </div>
      <template slot="footer-wrapper">
        <footer class="modal-footer">
          <CButton @click="closeActivateDomainModal">{{ $t('Cancel') }}</CButton>
          <CButton
              type="submit"
              color="primary"
              size="sm"
              @click="activateDomain(selectedDomain)"
          >
            <span>{{ $t('proceed') }}</span>
          </CButton>
        </footer>
      </template>
    </CModal>
  </div>
</template>

<script>
import axios from "@/plugins/axios.js";
import {Websocket} from "@/plugins/websocket.js";
import EditDomain from "./EditDomain";
import PatchDomain from "./PatchDomain";
import SslConfig from "./SslConfig";
import Diagnose from "./Diagnose.vue";
import ClusterUpgrade from "./ClusterUpgrade.vue";
import WidgetsStat from '../widgets/WidgetsStat'
import { bus } from '@/main';
import BatchOperationGroup from "./BatchOperationGroup";
import {EventBus} from "@/plugins/event-bus.js";
import Driver from "driver.js"
import "driver.js/dist/driver.min.css"
import getDefaultDomainConfig from '@/utilities/getDefaultDomainConfig';
import {subscriptionPlan} from '../../utilities/api';
import BatchDiagnose from '@/views/domain/BatchDiagnose';
import {delay, truncate, isArray} from 'lodash';
import {DomainAction, CertAction, ShadowDomainAction} from '@/utilities/websocket';
import {ExportDomainMixin, COLUMN_KEYS} from '@/mixins/ExportDomainMixin';
import {LIMIT_BATCH_DEPLOY} from '@/utilities/batch';
import {MIXED_VALUE, UPDATER_MESSAGE} from '@/utilities/constants';
import {deleteshadowDomain} from '@/utilities/api';
import PreSetDomainConfiguration from "./PreSetDomainConfiguration";
import { Modal } from "ant-design-vue";

const SHOW_DOMAIN_NOTES_STORAGE_KEY = 'SHOW_DOMAIN_NOTES';
let SHOW_DOMAIN_NOTES_DEFAULT = false;
if (window.localStorage) {
  SHOW_DOMAIN_NOTES_DEFAULT = localStorage.getItem(SHOW_DOMAIN_NOTES_STORAGE_KEY) === "true";
}

export default {
  name: "List",
  mixins: [ExportDomainMixin],
  components: {
    BatchDiagnose,
    EditDomain,
    PreSetDomainConfiguration,
    PatchDomain,
    SslConfig,
    Diagnose,
    ClusterUpgrade,
    WidgetsStat,
    BatchOperationGroup,
  },
  watch: {
    is_domain_ws_open() {
      if (this.is_domain_ws_open) {
        this.listenWs();
      }
    },
    is_issue_ws_open() {
      if (this.is_issue_ws_open) {
        this.listenWs();
      }
    },
    is_shadow_ws_open() {
      if (this.is_shadow_ws_open) {
        this.listenWs();
      }
    },
    sslModal: function(newSslModal, oldSslModal) {
      const fromVisibleToHidden = oldSslModal && !newSslModal;

      // when SslConfig is opened the issue websocket onMessage is overridden for SslConfig specific logic.
      // so when SslConfig is closed we attach onMessage again that is for List.vue
      if (fromVisibleToHidden) {
        this.attachIssueWsHandler();
      }

      if (!this.sslModal) {
        this.attachShadowWsHandler();
      }
    },
    editModal() {
      if (!this.editModal) {
        this.attachDomainWsHandler();
      }
    },
    showDomainNotes(value) {
      if (window.localStorage) {
        localStorage.setItem(SHOW_DOMAIN_NOTES_STORAGE_KEY, value);
      }
    }
  },
  computed: {
    is_issue_ws_open() {
      if (this.issue_ws) {
        return this.issue_ws.isOpen();
      }
      return false;
    },
    is_shadow_ws_open() {
      if (this.shadow_ws) {
        return this.shadow_ws.isOpen();
      }
      return false;
    },
    is_domain_ws_open() {
      if (this.domain_ws) {
        return this.domain_ws.isOpen();
      }
      return false;
    },
    issue_ws: {
      get() {
        return this.$store.state.websocket.issue_ws
      },
      set(value) {
        this.$store.state.websocket.issue_ws = value
      }
    },
    shadow_ws: {
      get() {
        return this.$store.state.websocket.shadow_ws;
      },
      set(value) {
        this.$store.state.websocket.shadow_ws = value
      }
    },
    domain_ws: {
      get() {
        return this.$store.state.websocket.domain_ws;
      },
      set(value) {
        this.$store.state.websocket.domain_ws = value;
      }
    },
    diagnoseTitle: function(){
      var title = this.$t('domain.DomainStatusCheck');
      if(this.selectedDomain) {
        title += " [" + this.selectedDomain.name + "]"
      }
      return title
    },
    selectedDomains: function () {
      return this.items.filter(i => i._selected === true)
    },
    domainListFields: function() {
      let columns = [
        {
          key: "domain",
          label: this.$t("domain.Domain"),
          _style: "width:15%"
        },
        {
          key: "shadows",
          label: this.$t("domain.ShadowDomain"),
          _style: "width:15%"
        },
        {
          key: "sourceConfig",
          label: this.$t("domain.SourceConfig"),
          _style: "width:15%;",
          filter: true
        },
        // {
        //   key: "cname",
        //   label: this.$t("domain.Cname"),
        //   _style: "width:15%;"
        // },
        {
          key: "status",
          label: this.$t("domain.Status"),
          _style: "width:10%;",
          filter: false
        },
        {
          key: "lastDeployed",
          label: this.$t("domain.DeploymentActivity"),
          _style: "width:15%;",
          sorter: false,
          filter: false
        },
        {
          key: "actions",
          label: this.$t("domain.Action"),
          _style: "width:30%",
          sorter: false,
          filter: false
        },
      ];
      
      if (this.showDomainNotes) {
        columns.splice(3, 0, {
          key: "notes",
          label: this.$t("notes"),
          _style: "width:15%;",
          filter: true
        });
      }

      return columns;
    }
  },

  data() {
    return {
      is_cname_loaded: false,
      link_new_version: "https://www.asians.group",
      items: [],
      driver: null,
      search: "",
      enableSelect: false,
      total_rows: 0,
      cname: "",
      plan: null,
      columnFilter: {
         external: true, lazy: true
      },
      dg: null,
      diagnoseModal: false,
      showSummaryDiagnoseModal: false,
      diagnoseKey: 0,
      show: false,
      listLoading: false,
      queryParams: {},
      page: 1,
      total_pages: 0,
      perpage: 100,
      editModal: false,
      presetModal: false,
      patchModal: false,
      sslModal: false,
      clusterUpgradeModal: false,
      defaultDomain: this.getDefaultDomain(),
      selectedDomain: null,
      domainStatus: {
        labelOn: "running",
        labelOff: "disabled"
      },
      showConfirmActivateModal: false,
      queueDomainDeploy: [],
      onProgressDomainDeploy: [],
      queueDomainCleanCache: [],
      onProgressDomainCleanCache: [],
      queueDomainDeactivate: [],
      onProgressDomainDeactivate: [],
      queueDomainResume: [],
      onProgressDomainResume: [],
      queueDomainDelete: [],
      onProgressDomainDelete: [],
      showDomainNotes: SHOW_DOMAIN_NOTES_DEFAULT,
      certStatus: null,
      domainStatus: null,
      sortByFilter: 'newest',
      findBtnClicked: false,
      messageTimeout: 900000, // timeout in ms
      lastMessageTime: null,
      deploy_result: false,
      selectedDomainCount: 0,
      currentTime: this.$moment(),
      intervalId: null,
    };
  },
  props: {
    caption: {
      type: String,
      default: 'domain.DomainList'
    },
    group: Object,
    hover: Boolean,
    striped: Boolean,
    bordered: Boolean,
    fixed: Boolean,
    dark: Boolean
  },

  created() {
    this.selectedDomain = this.getDefaultDomain();
    // this.selectedDomain = newDomain;
    this.listenEvent(); 

    this.openWs();
    this.listenWs();

  },

  mounted() {
    // this.selectedDomain = this.getDefaultDomain();
    this.fetchSubscriptions()

    this.intervalId = setInterval(() => {
      this.currentTime = this.$moment();
    }, 60000);
  },
  beforeDestroy() {
    this.closeWs()

    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  },
  methods: {
    closeFindMultiDomain() {
      this.findDomainModal=!this.findDomainModal
      this.findMultiDomain = '';
  },
    showDomainTooltip(domain, content, type, delay=5000) {
      domain._message = {
        content: content,
        show: true,
        type: type
      }
      this.$refs.domainTable.$forceUpdate();

      setTimeout(() => {
        domain._message.show = false;
        this.$refs.domainTable.$forceUpdate();
      }, delay)
    },
    openWs() {
      this.domain_ws = new Websocket(`domain/`);
      this.issue_ws  = new Websocket('cert/', process.env.VUE_APP_CERT_WSS);
      this.shadow_ws = new Websocket('shadow-domain/');
    },
    listenWs() {
      this.domain_ws.onopen = function(event) {
        console.log("Successfully connected to the domain websocket server...")
      }
      this.issue_ws.onopen = function(event) {
        console.log("Successfully connected to the issue websocket server...")
      }
      this.shadow_ws.onopen = function(event) {
        console.log("Successfully connected to the shadow websocket server...")
      }

      this.attachDomainWsHandler();
      this.attachIssueWsHandler();
      this.attachShadowWsHandler();
    },

    onPatchResume() {
      this.onProgressDomainResume.forEach(domain => {
        domain = this.items.find(d => d.name === domain.name);
        domain.deploy_status = "running";
        this.showDomainTooltip(domain, this.$i18n.t('DeployActionResume'), 'success');
      })
    },
    onPatchDeactivate() {
      this.onProgressDomainDeactivate.forEach(domain => {
        domain = this.items.find(d => d.name === domain.name);
        domain.deploy_status = "running";
        this.showDomainTooltip(domain, this.$i18n.t('DeployActionDeactivate'), 'success');
      })
    },
    onPatchDelete() {
      this.onProgressDomainDelete.forEach(domain => {
        domain = this.items.find(d => d.name === domain.name);
        domain.deploy_status = "running";
        this.showDomainTooltip(domain, this.$i18n.t('DeployActionDelete'), 'success');
      })
    },
    attachDomainWsHandler() {
      this.domain_ws.onMessage = function(event, result) {
        if (result.data.hasOwnProperty('error')) {
          let domain = this.items.find(d => d.name === result.data.domain.name);

          if (result.data.error == UPDATER_MESSAGE) {
            this.showDomainTooltip(domain, this.$i18n.t('DeploymentQueueMessage'), 'success')
            return;
          }

          domain.deploy_status = "done";
          this.$refs.domainTable.$forceUpdate();
          this.showDomainTooltip(domain, result.data.error, 'danger', 8000)
          return;
        }
        let domain = this.items.find(d => d.name == result.data.domain.name);

        if (result.action === DomainAction.DOMAIN_REDEPLOY || result.action === DomainAction.BATCH_REDEPLOY_DOMAIN) {
          this.deploy_result = false
          this.lastMessageTime = Date.now()
          setTimeout(this.checkMessageTimeout(domain), this.messageTimeout);
          if (result.data.dp.success) {

            if (domain) {
              this.onProgressDomainDeploy = this.onProgressDomainDeploy.filter(item => item.name !== domain.name);
              const domain_deploy = this.queueDomainDeploy.shift();
              if (domain_deploy) {
                this.onProgressDomainDeploy.push(domain_deploy);
                this.sendDeploy(result.action, [domain_deploy]);
              }
              domain.deploy_status = "done";
            }
            this.$refs.domainTable.$forceUpdate();
            
            if (result.data.dp.result && result.data.dp.result.success) {
              this.deploy_result = true
              this.showDomainTooltip(domain, this.$i18n.t('DeployActionSuccessful'), "success", 8000);
            } else {
              this.showDomainTooltip(domain, this.$i18n.t('DeployActionFail'), "danger", 8000);
            }
          }
        } else if (result.action === DomainAction.CLEAR_CACHE) {
          if (result.data.dp.success) {
            this.onProgressDomainCleanCache = this.onProgressDomainCleanCache.filter(item => item.name !== domain.name);
            const domain_clean_cache = this.queueDomainCleanCache.shift();
            if (domain_clean_cache) {
              this.onProgressDomainCleanCache.push(domain_clean_cache);
              this.sendDeploy(DomainAction.CLEAR_CACHE, [domain_clean_cache])
            }
            this.$set(domain, '_patch_status', "done")
            if (result.data.dp.result && result.data.dp.result.success) {
              this.showDomainTooltip(domain, this.$i18n.t('ClearCacheActionSuccessful'), 'success')
            } else {
              this.showDomainTooltip(domain, this.$i18n.t('ClearCacheActionFail'), 'danger')
            }
          }
        } else if (result.action === DomainAction.DOMAIN_DEACTIVATE) {
          if (result.data.dp.success) {
            domain.status = "disabled";
            if (result.data.dp.result && result.data.dp.result.success) {
              EventBus.$emit('updateDomainStat');
              this.showDomainTooltip(domain, this.$i18n.t('DeactivateActionSuccessful'), 'success', 8000);
            } else {
              this.showDomainTooltip(domain, this.$i18n.t('DeactivateActionFail'), 'danger', 8000);
            }

            // Remove item from queue
            if (this.queueDomainDeactivate.length === 0) {
              this.$refs.patchDomainModal.processing = false;
            }
            this.queueDomainDeactivate = this.queueDomainDeactivate.filter(item => item.name !== domain.name);
            this.onProgressDomainDeactivate = this.onProgressDomainDeactivate.filter(item => item.name !== domain.name);
            const domain_deploy = this.queueDomainDeactivate.shift();
            if (domain_deploy) {
              this.onProgressDomainDeactivate.push(domain_deploy);
              this.sendDeploy(DomainAction.DOMAIN_DEACTIVATE, [domain_deploy]);
            }

            domain.deploy_status = "done";
            domain.status = "disabled";
            this.$refs.domainTable.$forceUpdate();
          }
        } else if (result.action === DomainAction.DOMAIN_RESUME) {
          if (result.data.dp.success) {
            domain.status = "running";
            if (result.data.dp.result && result.data.dp.result.success) {
              EventBus.$emit('updateDomainStat');
              this.showDomainTooltip(domain, this.$i18n.t('ResumeActionSuccessful'), 'success', 8000);
            } else {
              this.showDomainTooltip(domain, this.$i18n.t('ResumeActionFail'), 'danger', 8000);
            }

            // Remove item from queue
            if (this.queueDomainResume.length === 0) {
              this.$refs.patchDomainModal.processing = false;
            }
            this.queueDomainResume = this.queueDomainResume.filter(item => item.name !== domain.name);
            this.onProgressDomainResume = this.onProgressDomainResume.filter(item => item.name !== domain.name);
            const domain_deploy = this.queueDomainResume.shift();
            if (domain_deploy) {
              this.onProgressDomainResume.push(domain_deploy);
              this.sendDeploy(DomainAction.DOMAIN_RESUME, [domain_deploy]);
            }

            domain.deploy_status = "done";
            this.$refs.domainTable.$forceUpdate();
          }
        } else if (result.action === DomainAction.DOMAIN_DELETE) {
          if (result.data.dp.success) {
            if (result.data.dp.result.success) {
              EventBus.$emit('updateDomainStat');
              this.showDomainTooltip(domain, this.$i18n.t('DeleteActionSuccessful'), 'success', 8000);
              this.items = this.items.filter(item => item.name !== domain.name);
            } else {
              domain.deploy_status = "done";
            }

            // Remove item from queue
            if (this.queueDomainDelete.length === 0) {
              this.$refs.patchDomainModal.processing = false;
            }
            this.queueDomainDelete = this.queueDomainDelete.filter(item => item.name !== domain.name);
            this.onProgressDomainDelete = this.onProgressDomainDelete.filter(item => item.name !== domain.name);
            const domain_deploy = this.queueDomainDelete.shift();
            if (domain_deploy) {
              this.onProgressDomainDelete.push(domain_deploy);
              this.sendDeploy(DomainAction.DOMAIN_DELETE, [domain_deploy]);
            }

            this.$refs.domainTable.$forceUpdate();
          }
        }
      }.bind(this);
    },

    attachIssueWsHandler() {
      this.issue_ws.onMessage = function (event, data) {
        if (data.data.error) {
          if (data.data.error.cert_issues) {
            for (let field in data.data.error.cert_issues[0]) {
              const msg = data.data.error.cert_issues[0][field].pop();
              this.flash(field + ': ' + msg, "error", {"timeout": 3000});
            }
          } else if (data.data.error) {
            this.flash(data.data.error, "error", {"timeout": 3000});
          } else {
            this.flash(this.$t('message.ErrorCertificateGeneration'), "error", {"timeout": 3000});
          }

          return;
        }

        const dp = data.data.dp;
        if (dp.success) {
          this.flash(this.$t('message.CertificateIssued'), "success", { timeout: 3000 });
          this.refresh();
        }
      }.bind(this);
    },

    attachShadowWsHandler() {
      this.shadow_ws.onMessage = function(event, result) {
        const main_domain = result.data.domain;
        const shadow_updated = result.data.shadow;
        const domain = this.items.find(item => item.name === main_domain);
        const shadow = domain.shadows.find(item => item.name === shadow_updated.name);
        if (result.action === ShadowDomainAction.CNAME_CHECK_ERROR) {
          this.$set(shadow, 'sslConfig', shadow_updated.sslConfig);
          this.$set(shadow, 'deploy_status', shadow_updated.deploy_status);
          this.$set(shadow, 'error', result.data.error);
          return
        }
        this.$set(shadow, 'sslConfig', shadow_updated.sslConfig)
        this.$set(shadow, 'deploy_status', shadow_updated.deploy_status);
        this.$set(shadow, 'error', null);
      }.bind(this)
    },

    checkMessageTimeout(domain) {
      const currentTime = Date.now();
      const elapsedTime = currentTime - this.lastMessageTime
      if (this.deploy_result == false) {
        if (elapsedTime >= this.messageTimeout) {
          domain.deploy_status = "ready";
          this.$refs.domainTable.$forceUpdate();
          this.showDomainTooltip(domain, this.$i18n.t('DeployActionFail'), "danger", 3000);
        }
      }
    },

    closeWs() {
      this.domain_ws.connection.close();
      this.issue_ws.connection.close();
      this.shadow_ws.connection.close();
    },

    async sendDeploy(action, domains) {
      var domain_names = domains.map(d=>d.name)
      if (action === DomainAction.DOMAIN_REDEPLOY || action === DomainAction.BATCH_REDEPLOY_DOMAIN) {
        domains.forEach(domain => {
          let foundDomain = this.items.find(d => d.name === domain.name);
          if (foundDomain) {
            foundDomain.deploy_status = "running";
            this.showDomainTooltip(foundDomain, this.$i18n.t('DeployActionRunning'), 'success');
          }
        });
        this.$refs.domainTable.$forceUpdate();
      } else if (action === DomainAction.CLEAR_CACHE) {
        domains.forEach(domain => {
          domain = this.items.find(d => d.name === domain.name);
          this.$set(domain, '_patch_status', "processing")
          this.showDomainTooltip(domain, this.$i18n.t('ClearCacheActionRunning'), 'success')
        })
      } else if (action === DomainAction.DOMAIN_DEACTIVATE) {
        domains.forEach(domain => {
          domain = this.items.find(d => d.name === domain.name);
          domain.deploy_status = "running";
          this.showDomainTooltip(domain, this.$i18n.t('DeployActionDeactivate'), 'success');
        });
      } else if (action === DomainAction.DOMAIN_RESUME) {
        domains.forEach(domain => {
          domain = this.items.find(d => d.name === domain.name);
          domain.deploy_status = "running";
          this.showDomainTooltip(domain, this.$i18n.t('DeployActionResume'), 'success');
        });
      } else if (action === DomainAction.DOMAIN_DELETE) {
        domains.forEach(domain => {
          domain = this.items.find(d => d.name === domain.name);
          domain.deploy_status = "running";
          this.showDomainTooltip(domain, this.$i18n.t('DeployActionDelete'), 'success');
        });
      }

      this.domain_ws.sendMessage(action, {domains: domain_names});
    },

    sendIssue(action, domains) {
      const cert_issues = []
      const filtered_domains = domains.filter(item => {
        if( item.sslConfig && item.sslConfig.enabled == true ) {
          this.showDomainTooltip(item, this.$t('message.SSLAlreadyEnabled'), 'success', 3000)
          this.$set(item, '_patch_status', "done")
          return null
        }
        this.$set(item, '_patch_status', "processing")
        cert_issues.push({
          "cert_type": process.env.NODE_ENV === 'development' ? 'DNS' : 'AUTO',
          "name": item.name,
          "cname": item.cname,
          "model_name": item.model_name ? item.model_name : 'shadowdomain',
          "forceSSL": true
        });
        return item
      });

      this.issue_ws.sendMessage(action, {cert_issues: cert_issues})

      return cert_issues.length > 0;
    },

    startSelect() {
      this.enableSelect = true
    },

    endSelect() {
      this.enableSelect = false
      this.patchModal = false
      this.items.forEach((item)=>{
        this.$set(item, '_selected', false )
        this.$set(item, '_classes', "" )
      });
      this.selectedDomainCount = 0;
    },

    startImport() {

    },

    selectAll() {
      this.items.forEach( ( item ) => {
        this.$set(item, '_selected', true )
        this.$set(item, '_classes', item._selected ? "alert-primary" : "" )
      });
      this.updateSelectedDomainCount();
    },

    pageChanged(itemsPerPage) {
      this.perpage = itemsPerPage;
      this.refresh();
    },

    selectRow(item, index, column_name, event) {
      if(this.enableSelect) {
        this.$set(item, '_selected', !item._selected )
        this.$set(item, '_classes', item._selected ? "alert-primary" : "" )
        this.updateSelectedDomainCount();
      }
      //this.$refs.domainTable.refresh();
    },
    updateSelectedDomainCount() {
    this.selectedDomainCount = this.items.filter((item) => item._selected).length;
  },
    makeDriver(){
      this.driver = new Driver({
        //className: "scoped-class", // className to wrap driver.js popover
        animate: true, // Animate while changing highlighted element
        stageBackground: 'rgba(255, 255, 255, 0.3)',   // This will override the one set in driver
        opacity: 0.1, // Background opacity (0 means only popovers and without overlay)
        padding: 10, // Distance of element from around the edges
        allowClose: true, // Whether clicking on overlay should close or not
        overlayClickNext: false, // Should it move to next step on overlay click
        doneBtnText: this.$t('Done'), // Text on the final button
        closeBtnText: this.$t('Close'), // Text on the close button for this step
        nextBtnText: this.$t('Next'), // Next button text for this step
        prevBtnText: this.$t('Prev'), // Previous button text for this step
        // onHighlightStarted: (Element) => {
        //   this.addDomain() ; // = true;
        // }
        // onNext: (Element) => {
        //   if( Element.node.id == "add-domain" ) {
        //     this.driver.preventMove();
        //     this.driver.moveNext();
        //   }
        // } // Called when element is about to be highlighted
        // Called when moving to next step on any step
      })
    },
    guide () {
      this.makeDriver();
      this.driver.defineSteps([
        {
          element: "#add-domain",
          popover: {
            title: this.$t('guide.TitleAddDomain'),
            description: this.$t('guide.DescriptionAddDomain'),
            position: "left"
          },
          onNext: () => {
            // Prevent moving to the next step
            this.driver.preventMove();

            this.addDomain() ; // = true;
            this.cancelAddDomain();

            setTimeout(() => {
              this.driver.moveNext();
            }, 100);
          }
        },
        {
          element: "#edit-input-domain",
          popover: {
            title: this.$t('guide.TitleEditDomain'),
            description: this.$t('guide.DescriptionEditDomain'),
            position: "bottom"
          },
        },
        {
          element: "#edit-input-source",
          popover: {
            title: this.$t('guide.TitleEditSource'),
            description: this.$t('guide.DescriptionEditSource'),
            position: "bottom"
          }
        },
        {
          element: "#edit-submit",
          popover: {
            title: this.$t('Save'),
            position: "left"
          },
          onNext: () => {
            // Prevent moving to the next step
            this.driver.preventMove();
            this.editModal = false;
            setTimeout(() => {
              this.driver.moveNext();
            }, 100);
          }
        },
        {
          element: "#display-cname",
          popover: {
            title: this.$t('guide.TitleDisplayCNAME'),
            description: this.$t('guide.DescriptionDisplayCNAME'),
            position: "bottom"
          }
        },
        {
          element: "#member-plans",
          popover: {
            title: this.$t('guide.TitleMemberPlan'),
            description: this.$t('guide.DescriptionMemberPlan'),
            position: "bottom"
          },
          onNext: () => {
            // Prevent moving to the next step
            this.driver.preventMove();
            EventBus.$emit('show-header-dropdown');
            setTimeout(() => {
              this.driver.moveNext();
            }, 100);
          }
        },
        {
          element: "#contact-support",
          popover: {
            title: this.$t('guide.TitleContactSupport'),
            description: this.$t('guide.DescriptionContactSupport'),
            position: 'left-bottom',
          }
        }
      ]);
      this.driver.reset();
      this.driver.start();
    },
    getDefaultDomain() {
      return getDefaultDomainConfig();
    },
    patchSave(domain,shadowdomaindelete) {
      //別問我為什麼要這麼寫，我也看不懂。
      (async () => {
        let notified = false; // used to limit notification to 1
        for (const item of this.selectedDomains) {
          if (domain.sourceConfig.full_url === MIXED_VALUE) {
            // noop
          }
          else if ( domain.sourceConfig.full_url ) {
            item.sourceConfig.full_url = domain.sourceConfig.full_url
          }
          if (domain.cache_reusable){
            item.cache_reusable = domain.cache_reusable
          }
          else{
            item.cache_reusable = false
          }
          
          if (domain.sourceConfig.redirect === MIXED_VALUE) {
            // noop
          }
          else {
            item.sourceConfig.redirect = domain.sourceConfig.redirect; 
          }

          if (domain.sourceConfig.hasOwnProperty('is_bypass_acme')) {
            item.sourceConfig.is_bypass_acme = domain.sourceConfig.is_bypass_acme;
          }
          
          if (domain.notes === MIXED_VALUE) {
            // noop
          } 
          else {
            item.notes = domain.notes;
          }

          // domain.strategies is null if the selected domains have different advanced rules.f
          // if domain.strategies is null we retain the selected domain advanced rules.
          if (domain.strategies === null) {
            // noop
          }
          else if (isArray(domain.strategies)) {
            item.strategies = domain.strategies
          }
          else if ( domain._strategies_cleanall ) {
            item.strategies = []
          }

          if (domain.cacheConfig.cache_type) {
            item.cacheConfig.cache_type = domain.cacheConfig.cache_type;
            item.cacheConfig.nocache = domain.cacheConfig.nocache || [];
          }

          if (domain.guardConfig) {
            item.guardConfig = {...item.guardConfig, ...domain.guardConfig};
          }
          if (domain.sslConfig.hasOwnProperty('is_hsts')) {
            item.sslConfig.is_hsts = domain.sslConfig.is_hsts;
          } else {
            item.sslConfig.is_hsts = false;
          }
          if (domain.sslConfig.hasOwnProperty('is_wildcard_hsts')) {
            item.sslConfig.is_wildcard_hsts = domain.sslConfig.is_wildcard_hsts;
          } else {
            item.sslConfig.is_wildcard_hsts = false;
          }

          if (domain.sslConfig.hasOwnProperty('isAutoGenerateCertificate')) {
            item.sslConfig.isAutoGenerateCertificate = domain.sslConfig.isAutoGenerateCertificate;
          } else {
            item.sslConfig.isAutoGenerateCertificate = false;
          }

          // promises.push(
          let response = await axios({
            method: "PATCH",
            url: `domain/{domain}/`,
            data: item,
            urlParams: {
              domain: item.name
            }
          }).catch(errors => {
            errors.forEach((message) => {
              this.flash(message, 'error', { "timeout": 5000 });
            });
          })
          if (response.status == "200") {
            EventBus.$emit('updateDomainStat');
            this.flash( item.name + " " + this.$t("domain.SaveSuccess"), "success", {
              timeout: 5000
            });
            let generateCertificate = true;
            if (domain.sslConfig && !domain.sslConfig.isAutoGenerateCertificate) {
              generateCertificate = false;
            }

            if (generateCertificate && !notified) {
              let total_time = 30 + 60;
              let alpha = 5; 
              domain.shadows.forEach(() => {
                total_time += alpha + 60;
                alpha += 2;
              });

              total_time = Math.round(total_time / 60)
              this.flash(this.$t("domain.AutoGenerateSSL", {total_time: total_time}), "success", {
                timeout: 5000
              });
              notified = true;
            }
          }
        }
        if (shadowdomaindelete != '') {
          try {
            const shadowdomaindeleted = await deleteshadowDomain(shadowdomaindelete);
            EventBus.$emit('updateDomainStat');
            EventBus.$emit('shadowdomainarea');
          } catch (error) {
            console.log(error);
          }
        }
      })().then(() => {
        this.endSelect();
        this.refresh();
      })
    },
    patchCleancache() {
      if (!this.domain_ws.isOpen()) {
        this.flash(this.$t('message.WebsocketNotOpen'), 'error', {'timeout': 8000});
        return;
      }
      this.queueDomainCleanCache = this.selectedDomains;
      this.onProgressDomainCleanCache = this.queueDomainCleanCache.splice(0, LIMIT_BATCH_DEPLOY);
      this.queueDomainCleanCache = this.queueDomainCleanCache.filter(item => {
        this.$set(item, '_patch_status', "processing")
        let result = true;
        this.onProgressDomainCleanCache.forEach(t => {
          if (item.name === t.name) {
            result = false;
          }
        })
        return result;
      });
      this.sendDeploy(DomainAction.CLEAR_CACHE, this.onProgressDomainCleanCache)
      this.endSelect();
    },
    saveDomain(domain) {
      let isNew = domain.pk == null;
      if(! domain.cname) {
        domain._cname = this.cname;
      }
      domain.guardConfig.blacklistMode = "blacklistMode";
      domain.guardConfig.whitelistMode = "whitelistMode";
      axios({
        method: isNew ? "POST" : "PATCH",
        url: isNew
          ? `domain/`
          : `domain/{domain}/`,
        data: domain,
        urlParams: {
          domain: domain.name
        }
      }).then(response => {
          domain = response.data;
          if (response.status == "201") {
            this.flash(this.$t("domain.SaveSuccess"), "success", {
              timeout: 5000
            });

            let total_time = 30 + 60;
            let alpha = 5; 
            for (let _ in domain.shadows) {
              total_time += alpha + 60;
              alpha += 2;
            }

            total_time = Math.round(total_time / 60)

            let generateCertificate = true;
            if (domain.sslConfig && !domain.sslConfig.isAutoGenerateCertificate) {
              generateCertificate = false;
            }

            if (generateCertificate) {
              this.flash(this.$t("domain.AutoGenerateSSL", {total_time: total_time}), "success", {
                timeout: 5000
              });
            }
          }
        this.refresh();
        this.editModal = false
          //this.$emit('hide');
        })
        .catch(errors => {
          errors.forEach((message) => {
            if (message.includes("0.")) {
              message = message.replace("0.", "");
              EventBus.$emit('domainError', message);
              this.flash(message,'error', { "timeout": 5000 });
            }else{
              const messages = message.replace("detail.", "");
              this.flash(messages,'error', { "timeout": 5000 });
            }
          });
        });
    },
    saveShadow(shadow, domain) {
      let isNew = shadow.id == null;
      axios({
        method: isNew ? "POST" : "PATCH",
        url: isNew
          ? `domain/{domain}/shadow/`
          : `domain/{domain}/shadow/{shadow}/`,
        data: shadow,
        urlParams: {
          domain: domain.name,
          shadow: shadow.name
        }
      }).then(response => {
          shadow = response.data;
          if (response.status == "201") {
            this.flash(this.$t("domain.SaveSuccess"), "success", {
              timeout: 5000
            });
          }
        })
        .catch(errors => {
          errors.forEach((message) => {
            this.flash(message, 'error', { "timeout": 5000 });
          });
        });
    },
    closeActivateDomainModal() {
      this.selectedDomain = null;
      this.showConfirmActivateModal = false;
    },
    async activateDomain(domain) {
      if (!this.domain_ws.isOpen()) {
        this.flash(this.$t('message.WebsocketNotOpen'), 'error', {'timeout': 8000});
        return;
      }
      await this.sendDeploy(DomainAction.DOMAIN_RESUME, [domain]);
      this.selectedDomain = null;
      this.showConfirmActivateModal = false;
    },
    getStatusIcon(domain) {
      if (domain.deploy_status == 'running') {
        return '';
      }

      return "cil-media-play";
    },
    getLink(domain) {
      let hh = "http";
      if (domain.sslConfig && domain.sslConfig.enabled) {
        hh = "https";
      }
      return `${hh}://${domain.name}/`;
    },
    filterBySearch() {
      this.page = 1;
      this.fetchDomain();
    },
    applyFilter() {
      this.page = 1;
      this.fetchDomain();
    },
    listenEvent() {
      // Send the event on a channel (i-got-clicked) with a payload (the click count.)
      EventBus.$on("show-guide", () => {
        this.guide()
      });
      EventBus.$on('DeleteDomainRunningNotification', domain => {
        domain = this.items.find(d => d.name === domain.name);
        domain.deploy_status = "running";
        this.showDomainTooltip(domain, this.$i18n.t('DeployActionDelete'), 'success');
      });
    },

    onChangeQuery(queryParams) {
      this.queryParams = queryParams;
      this.showLoader = true;
      this.fetchDomain();
    },
    addDomain() {
      this.selectedDomain = this.getDefaultDomain();
      this.editModal = false;
      this.editModal = true;
    },
    cancelAddDomain() {
      this.selectedDomain = this.getDefaultDomain();
      this.presetModal = true;
      // this.presetModal = false;   
    },
    refresh(){
      this.fetchDomain();
    },
    editDomain(domain) {
      // this.selectedDomain = newDomain;
      this.selectedDomain = domain;
      this.editModal = false;
      this.editModal = true;
    },
    patchDomain() {
      if(  this.selectedDomains.length == 0) {
        this.flash(this.$t('message.PleaseCheckTheDomainNameFirst'), "error", { timeout: 3000 });
        return
      }
      this.patchModal = false;
      this.patchModal = true;
    },
    patchSSL() {
      //別問我為什麼要這麼寫，我也看不懂。
      const isGeneratingForDomains = this.sendIssue(CertAction.CERT_ISSUE, this.selectedDomains);
      if (isGeneratingForDomains) {
        this.flash(this.$t('message.GeneratingCertificateCloseSafe'), "info", { timeout: 8000 });
      }
      delay(this.endSelect, 1000);

      //別問我為什麼要這麼寫，我也看不懂。
      //( async () => {
        // const domainsTerminatedCheck = [];

        // for (let item of this.selectedDomains) {
          // if( item.sslConfig && item.sslConfig.enabled == true ) {
            // this.showDomainTooltip(item, this.$t('message.SSLAlreadyEnabled'), 'success', 3000)
            // this.$set(item, '_patch_status', "done")
          // }
          // else {
            // this.$set(item, '_patch_status', "processing")

            // let response;
            // try {
              // response = await axios({
                // method: "POST",
                // url: `cert/issue/issue/`,
                // data: {
                  // "issue_type": 'AUTO',
                  // "name": item.name,
                  // "aliases": item.aliases,
                  // "cname": item.cname,
                // },
              // });
            // } catch (errors) {
              // errors.forEach((message) => {
                // this.showDomainTooltip(item, message, 'danger', 5000)
              // });

              // this.$set(item, '_patch_status', "done");

              // continue;
            // }

            // let issueTaskResp = response.data;

            // const TERMINATED = 'TERMINATED';
            // const CHECK_LIMIT = 5;
            // let checks = 0;
            // let statusResponse = null;
            // if( issueTaskResp['tasks'] ) {
              // statusResponse = await new Promise(resolve => {
                // const interval = setInterval(async () => {
                  // const issueStatusResp = await axios
                    // .post("domain/tasks/status/", {
                      // tasks: issueTaskResp['tasks']
                    // })
                    // // .then(response => {
                    // //   this.issueTask = response.data;
                    // //   if (this.issueTask.state != "PENDING") {
                    // //     clearInterval(this.issuePolling)
                    // //     this.issuePolling = null;
                    // //     this.loadDomain()
                    // //   }
                    // // })
                    // // .catch(error => console.log(error));

                  // if ( issueStatusResp.data.every( (task) => task.state == 'SUCCESS' ) ) {
                    // resolve('');
                    // clearInterval(interval);
                  // } else {
                    // checks++;

                    // if (checks >= CHECK_LIMIT) {
                      // resolve(TERMINATED);
                      // clearInterval(interval);
                    // }
                  // }
                // }, 5000);
              // });
            // }

            // if (statusResponse === TERMINATED) {
              // domainsTerminatedCheck.push(item);
              // this.$set(item, '_patch_status', "done");
              // continue;
            // }

            // let domainResp;
            // try {
              // domainResp = await axios({
                // method: "GET",
                // url: `domain/{domain}/`,
                // data: {},
                // urlParams: {
                  // domain: item.name
                // }
              // });
            // } catch (errors) {
              // errors.forEach((message) => {
                // this.showDomainTooltip(item, message, 'danger', 5000)
              // });

              // continue;
            // }

            // let domain = domainResp.data;
            // domain.sslConfig.forceSSL = true

            // if ( domain.sslConfig.certType == "AUTO" ) {
              // try {
                // await axios({
                    // method: "POST",
                    // url: `domain/{domain}/config/`,
                    // data: {
                      // section: "sslConfig",
                      // config: domain.sslConfig
                    // },
                    // urlParams: {
                      // domain: domain.name
                    // }
                  // });

                // this.showDomainTooltip(item, this.$t('action.success'), 'success', 3000)
              // } catch (errors) {
                // errors.forEach((message) => {
                  // this.showDomainTooltip(item, message, 'danger', 5000)
                // });
              // }
            // }
            // else {
              // this.showDomainTooltip(item, this.$t('action.success'), 'success', 3000)
            // }

            // item = Object.assign({}, item, domain)
            // this.$refs.domainTable.$forceUpdate()
            // this.$set(item, '_patch_status', "done")
          // }
        // }

        // if (domainsTerminatedCheck.length) {
          // domainsTerminatedCheck.forEach(item => {
            // this.showDomainTooltip(item, this.$t('message.BatchHTTPSHasError', [item.name]), 'danger', 8000)
          // })
        // }
      // })().finally(() => {
        // this.endSelect();
        // // this.refresh();
      // })
    },
    enableForceSSL() {
      this.toggleForceSSL(true);
    },
    disableForceSSL() {
      this.toggleForceSSL(false);
    },
    async toggleForceSSL(isEnable = true) {

      function isDomainAllowed(domain) {
        if (!domain.sslConfig || !domain.sslConfig.enabled) {
          return false;
        }

        // is already in the desired action
        if (domain.sslConfig.forceSSL == isEnable) {
          return false;
        }

        return true;
      }

      const allAreNotHTTPSEnabled = this.selectedDomains.every((domain) => !domain.sslConfig || !domain.sslConfig.enabled)
      if (allAreNotHTTPSEnabled) {
        this.flash(this.$t('message.BatchSelectedDomainsNotHTTPSEnabled'), 'info', {"timeout": 5000});
        return;
      }

      const hasValidDomain = this.selectedDomains.some((domain) => isDomainAllowed(domain));
      if (!hasValidDomain) {
        const message = isEnable ? this.$t('message.BatchForceHTTPSNoEnableAction') : this.$t('message.BatchForceHTTPSNoDisableAction');
        this.flash(message, 'info', {"timeout": 5000});
        return;
      }

      let hasErrorResponse = false;
      for (let domain of this.selectedDomains) {
        if (!isDomainAllowed(domain)) {
          continue;
        }

        const sslConfig = {...domain.sslConfig, forceSSL: isEnable};

        let response;
        try {
          response = await axios({
            method: "POST",
            url: `domain/{domain}/config/`,
            urlParams: {
              domain: domain.name
            },
            data: {
              section: "sslConfig",
              config: sslConfig,
              shadows: domain.shadows
            }
          });
        } catch (errors) {
          errors.forEach((message) => {
            this.flash(message, 'error', {"timeout": 5000});
          });
        }

        if (!(response.status > 200)) {
          hasErrorResponse = true;
        }
      }

      this.endSelect();
      this.refresh();

      if (hasErrorResponse) {
        this.flash(this.$t('message.OperationCompletedWithError'), 'info', {"timeout": 5000});
        return;
      }

      const message = isEnable ? this.$t('message.BatchForceSSLEnabled') : this.$t('message.BatchForceSSLDisabled');
      this.flash(message, "success", { timeout: 3000 });
    },
    showSslConfig(domain) {
      if (domain.status === "disabled") {
        this.flash(this.$t('message.ssl_config_disabled'), 'error', { timeout: 5000 });
        return;
    }
      
      this.selectedDomain = domain;
      this.sslModal = true;
    },
    showClusterUpgrade(domain) {
      this.selectedDomain = domain;
      this.clusterUpgradeModal = true;
    },

    diagnose(domain) {
      this.selectedDomain = domain;
      this.diagnoseKey ++;
      this.diagnoseModal = true;
    },

    showDeleteConfirm(domain) {

    },

    cleancache(domain) {
      if (!this.domain_ws.isOpen()) {
        this.flash(this.$t('message.WebsocketNotOpen'), 'error', {'timeout': 8000});
        return;
      }
      this.sendDeploy(DomainAction.CLEAR_CACHE, [domain])
    },

    handleDeploy(domain) {
      if (domain.status == 'disabled') {
        this.selectedDomain = domain;
        this.showConfirmActivateModal = true;
      } else {
        this.redeploy(domain);
      }
    },

    redeploy(domain) {
      if (!this.domain_ws.isOpen()) {
        this.flash(this.$t('message.WebsocketNotOpen'), 'error', {'timeout': 8000});
        return;
      }
      this.sendDeploy(DomainAction.DOMAIN_REDEPLOY, [domain])
    },

    patchDeploy() {
      if (!this.domain_ws.isOpen()) {
        this.flash(this.$t('message.WebsocketNotOpen'), 'error', {'timeout': 8000});
        return;
      }
      this.queueDomainDeploy = this.selectedDomains;
      this.onProgressDomainDeploy = this.queueDomainDeploy.splice(0, LIMIT_BATCH_DEPLOY);
      this.queueDomainDeploy = this.queueDomainDeploy.filter(item => {
        item.deploy_status = "running";
        let result = true;
        this.onProgressDomainDeploy.forEach(t => {
          if (item.name === t.name) {
            result = false;
          }
        })
        return result;
      });
      this.sendDeploy(DomainAction.BATCH_REDEPLOY_DOMAIN, this.onProgressDomainDeploy)
      this.endSelect();
    },

    switchSubscription(subscription) {
      this.cname = subscription.cname + "." + subscription.czone
      this.plan = subscription.plan;
      this.$store.state.cname = this.cname

      this.refresh()
    },

    fetchSubscriptions(){
      return subscriptionPlan()
        .then(response => {
          let subscriptions = response.data;
          this.is_cname_loaded = true;
          if (subscriptions.length > 0 ) {
            this.plan = subscriptions[0].plan;
            this.cname = subscriptions[0].cname + "." + subscriptions[0].czone
            this.$store.state.cname = this.cname
          }
          else {
            axios
            .get('domain/user/config/', {
            })
            .then(response => {
              let userconfig = response.data;
              this.cname = userconfig.cname

              this.$store.state.cname = this.cname
            })
            .catch(error => {
              console.log(error);
            })
          }
          this.fetchDomain()
          // .then(() => {
          //   if(this.items.length == 0 ) {
          //     this.guide()
          //   }
          // });
        })
        .catch(error => {
          console.log(error);
        });
    },
    fetchDomain() {
      //const params = '?page=' + ctx.currentPage + '&size=' + ctx.perPage
      this.listLoading = true;
      let filterParams = {
        page: this.page,
        perpage: this.perpage,
        _cname: this.cname,
        sort_by: this.sortByFilter,
      }
      
      if((this.search)!="") {
        filterParams['search'] = this.search.trim()
      }else{
        filterParams['search'] = this.search
      }

      let searchDomains = [];
      if (this.findMultiDomain) {
        let findMultipleDomain = this.findMultiDomain.toLowerCase();
        findMultipleDomain = findMultipleDomain.split('\n');
        for (let i in findMultipleDomain) {
            const findDomainQ = findMultipleDomain[i].trim();
            if (findDomainQ == "") {
                continue;
            }
            if (findDomainQ.includes(' ')) {
                this.flash(this.$t('message.InvalidDomain',[findMultipleDomain[i]]),"error", { timeout: 3000 });
                return;
            }
            searchDomains.push(findDomainQ);
        }
        searchDomains = [...new Set(searchDomains)];
        filterParams['q1'] = searchDomains.join(",");
      }

      if (this.findBtnClicked) {
        this.findDomainModal = !this.findDomainModal;
        this.findBtnClicked = false;
      }

      if (this.certStatus) {
        filterParams['cert_status'] = this.certStatus;
      }
      if (this.domainStatus) {
        filterParams['status'] = this.domainStatus;
      }

      return axios
        .get('domain/', {
          params: filterParams,
          urlParams: {
          }
        })
        .then(response => {
          EventBus.$emit('updateDomainStat');
          this.items = response.data.results;
          this.items.forEach(item => {
            if (item.deploy_status == 'ready') {
              item.deploy_status = 'running'
            }
          })
          this.total_rows = response.data.count;
          this.total_pages = Math.ceil( this.total_rows / this.perpage );
          this.listLoading = false;
        })
        .catch(error => {
          this.listLoading = false;
          console.log(error);
        })
        .finally(() => {
          this.listLoading = false
        });
    },
    handleHideSslConfig() {
      this.refresh();
      this.sslModal = false;
    },
    doDiagnoseCheck() {
      if (this.selectedDomains.length == 0) {
        this.flash(this.$t('message.PleaseCheckTheDomainNameFirst'), "error", { timeout: 3000 });
        return;
      }

      this.showSummaryDiagnoseModal = true;
    },
    async exportAllDomain() {
      this.setExportImportLang();

      const body_domains = []
      this.selectedDomains.forEach(item => body_domains.push(item.name));

      let domains = await axios({
        method: "POST",
        url: `domain/filter_domains_by_name/`,
        data: {
          domains: body_domains
        }
      })
      domains = domains.data.domains;
      const data = [];
      domains.forEach(item => {
        data.push({
          [COLUMN_KEYS.NAME]: item.name,
          [COLUMN_KEYS.ORIGIN_IP]: item.sourceConfig.full_url,
          [COLUMN_KEYS.SHADOW_DOMAINS]: item.shadows.map(s => s.name).join(' '),
          [COLUMN_KEYS.STATUS]: item.status === 'running' ? this.$t('online') : item.status === 'disabled' ? this.$t('disabled') : this.$t('deleted'),
          [COLUMN_KEYS.NOTES]: item.notes
        })
      });

      await this._exportDomainData(data);
      this.endSelect();
    },
    truncateLongNotes(notes) {
      return truncate(notes, { length: 150 })
    },
    deploymentMessage(item) {
      if (!item.deploy_status || !item.deployed_time) return this.$t('domain.NoRecordedDeployments');

      const timeAgo = this.$moment(item.deployed_time).from(this.currentTime);

      switch (item.deploy_status) {
        case 'init':
          return `${this.$t('domain.Initialized')} ${timeAgo}`;
        case 'ready':
          return `${this.$t('domain.Ready')} ${timeAgo}`;
        case 'running':
          return `${this.$t('domain.CurrentlyRunning')}`;
        case 'done':
          return `${this.$t('domain.LastDeployed')} ${timeAgo}`;
        case 'error':
          return `${this.$t('domain.Failed')} ${timeAgo}`;
        default:
          return '';
      }
    }
  }
};
</script>

<style scoped>
.GrayLock {
  color: gray;
}
.GreenLock {
  color: green;
}

.source-config-value {
  display: flex; 
  align-items: center;
}

.source-config-value span {
  padding-left: 6px;
}

.domain-notes {
    white-space: nowrap;
    width: 100%;                   /* IE6 needs any width */
    overflow: hidden;              /* "overflow" value must be different from  visible"*/ 
    -o-text-overflow: ellipsis;    /* Opera < 11*/
    text-overflow:    ellipsis;    /* IE, Safari (WebKit), Opera >= 11, FF > 6 */
}
#filterBox {
  position: relative;
  margin-bottom: 10px;
  width: 100%;
  display: none;
}
#filterBox.Show {
  display: flex !important;
}
.pull-right {
  float: right;
}
.ant-btn {
  height: auto;
  color: #fff;
  background-color: #2eb85c;
  border-color: #2eb85c;
}
.ant-btn:hover{
    color: #fff;
    background-color: #26994d;
    border-color: #248f48;
}

.table td {
  vertical-align: middle;
}

.badge {
  font-size: .75rem;
  line-height: 1rem;
  font-weight: 500;
  color: rgb(31 41 55/1);
  padding-bottom: .25rem;
  padding-top: .25rem;
  padding-left: .625rem;
  padding-right: .625rem;
  background-color: rgb(243 244 246/1);
  border-radius: .25rem;
  margin: .15rem;
}
</style>
